import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectionsService {
  private _state?: string | UrlTree;
  private readonly router = inject(Router);

  authUrlTree = this.router.createUrlTree(['/', 'auth', 'sign-up']);
  signInUrlTree = this.router.createUrlTree(['/', 'auth', 'sign-in']);
  afterSignInUrlTree = this.router.createUrlTree(['/', 'drills', 'practice']);

  emailValidationUrlTree = this.router.createUrlTree(['/', 'auth', 'verify-email']);

  accountInitializationUrlTree = this.router.createUrlTree(['/', 'auth', 'create-profile']);

  defaultAuthenticatedUrlTree = this.afterSignInUrlTree;

  urlToChallenge(scope: string, identifier?: string) {
    return this.router.createUrlTree(['/', 'auth', 'take-challenge'], {
      queryParams: { scope, identifier },
    });
  }

  redirectToAuth(state?: string | UrlTree) {
    this._state = state;
  }

  redirectAfterSignIn() {
    if (!this._state) {
      this.router.navigateByUrl(this.defaultAuthenticatedUrlTree);
    } else {
      this.router.navigateByUrl(this._state);
    }
  }

  redirectAfterSignOut() {
    this.router.navigateByUrl(this.signInUrlTree);
  }

  redirectAfterAuthError() {
    // TODO: have proper error page for auth errors
    this.router.navigateByUrl(this.authUrlTree);
  }
}
